// src/controller/services/modifierServices.ts
import { app, getDBInstance, ObjectId, branchApp } from "./dbServices";

/* Modifier services */

export const addModifier = async ({ data,userType }: any) => {
  try {


    if (!data) {
      console.error("No data to insert");
      return;
    }

    const selectedApp = userType === "branch" ? branchApp : app;
    const branchIdObject = new ObjectId(data.branchId);

    const result = await getDBInstance(userType)
      .collection("modifiers")
      .insertOne({
        name: data.name,
        price: data.price,
        description: data.description,
        branchId: branchIdObject,
        metaData: {
          createdAt: new Date(),
          createdBy: data.createdBy || selectedApp.currentUser.id,
          modifiedAt: null,
          modifiedBy: null,
          isActive: data.isActive !== undefined ? data.isActive : true,
        },
      });

    return result;
  } catch (error) {
    console.error("Error adding modifier:", error);
  }
};

export const updateModifier = async ({ id, data,userType }: any) => {
  try {


    if (!id || !data) {
      console.error("Invalid modifier ID or data");
      return;
    }

    const objectId = new ObjectId(id);
    const selectedApp = userType === "branch" ? branchApp : app;

    const result = await getDBInstance(userType)
      .collection("modifiers")
      .updateOne(
        { _id: objectId },
        {
          $set: {
            name: data.name,
            description: data.description,
            price: data.price,
            metaData: {
              createdAt: new Date(),
              createdBy: data.createdBy || selectedApp.currentUser.id,
              modifiedAt: new Date(),
              modifiedBy: data.modifiedBy || selectedApp.currentUser.id,
              isActive: data.isActive !== undefined ? data.isActive : true,
            },
          },
        }
      );

    return result;
  } catch (error) {
    console.error("Error updating modifier:", error);
  }
};

export const deleteModifier = async ({ id ,userType}: any) => {

  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType).collection("modifiers").deleteOne({
    _id: id,
  });
  return result;
};

export const getModifiers = async ({
  page = 0,
  rowsPerPage = 10,
  branchId,
  userType,
}: any) => {

  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }

  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance(userType)
    .collection("modifiers")
    .count({
      branchId: branchId,
    });

  const result = await getDBInstance(userType)
    .collection("modifiers")
    .aggregate([
      {
        $match: {
          branchId: branchId,
        },
      },
      { $skip: skip },
      { $limit: limit },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOneModifier = async ({ id,userType }: any) => {

  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance(userType)
    .collection("modifiers")
    .aggregate([
      {
        $match: { _id: id },
      },
    ]);

  return result[0];
};
