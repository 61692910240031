import React, { useContext } from "react";
import { ErrorMessage, Form, Field, Formik } from "formik";
import Card from "components/card";
import { MdClose, MdFileUpload } from "react-icons/md";
import { CircularProgress } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import envConfig from "../../../../env/env.json";
import Select from "react-select";
import { IKUpload, IKContext } from "imagekitio-react";
import {
  publicKey,
  urlEndpoint,
  authenticator,
} from "controller/common/imageKit/imageKit";
import { RestaurantFormProps } from "../restaurant.interface";
import { UserContext } from "controller/context/userContext";

const mapContainerStyle = {
  height: "400px",
  width: "100%",
};

const RestaurantForm: React.FC<RestaurantFormProps> = ({
  initialValues,
  validationSchema,
  onSubmit,
  loading,
  options = [],
  brandImage,
  isUploading,
  bannerImage,
  isBannerUploading,
  onLogoSuccess,
  onBannerSuccess,
  onUploadProgress,
  onBannerUploadProgress,
  handleDeleteLogoFile,
  handleDeleteBannerFile,
  logoInputRef,
  bannerInputRef,
  location,
  address,
  // handleNext,
  categoryOptions = [], 
  cuisineOptions = [], 
  pinCode,
  mapCenterLocation,
  isMapVisible,
  handleMapClick,
  handlePinCodeChange,
  handlePinCodeBlur,
  handlePinCodeKeyDown,
  daysOfWeek,
  paymentStatusOptions = [], 
}) => {
  const onError = (error: any) => {
    console.error("Upload Error:", error);
  };

  const { userType } = useContext(UserContext);

  return (
    // <Card extra={"w-full h-full p-3"}>
    <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
      <div className="px-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmit(values);
            // handleNext();
          }}
          enableReinitialize={true}
        >
          {({ setFieldValue, values }) => (
            <Form>
              {/* Restaurant Name */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Restaurant Name*
                </label>
                <Field
                  type="name"
                  name="name"
                  className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Description Field */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Description
                </label>
                <Field
                  as="textarea"
                  name="description"
                  rows="4"
                  className="mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Contact Details */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Contact Details*
                </label>
                <Field
                  type="text"
                  name="contactDetails"
                  className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                />
                <ErrorMessage
                  name="contactDetails"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Username */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Email* {userType === "branch" && "(Read-Only)"}
                </label>
                <Field
                  type="text"
                  name="username"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
                    userType === "branch" ? "bg-gray-100 text-gray-500" : ""
                  }`}
                  readOnly={userType === "branch"}
                  title={userType === "branch" ? "This field is read-only" : ""}
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Password */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Password* {userType === "branch" && "(Read-Only)"}
                </label>
                <Field
                  type="password"
                  name="password"
                  className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
                    userType === "branch" ? "bg-gray-100 text-gray-500" : ""
                  }`}
                  readOnly={userType === "branch"}
                  title={userType === "branch" ? "This field is read-only" : ""}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Payment Status using Select */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Payment Status*
                </label>
                <Select
                  options={paymentStatusOptions}
                  name="paymentStatus"
                  onChange={(selectedOption) =>
                    setFieldValue("paymentStatus", selectedOption?.value)
                  }
                  value={paymentStatusOptions.find(
                    (option) => option.value === values.paymentStatus
                  )}
                />
                <ErrorMessage
                  name="paymentStatus"
                  component="div"
                  className="text-red-500"
                />
              </div>
              {/* Type */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Type*
                </label>
                <Select
                  options={options}
                  name="type"
                  onChange={(selectedOption) =>
                    setFieldValue("type", selectedOption?.value)
                  }
                  value={options.find((option) => option.value === values.type)}
                />
                <ErrorMessage
                  name="type"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Category Field */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Category*
                </label>
                <Select
                  isMulti
                  options={categoryOptions}
                  name="category"
                  value={values.category.map(
                    (catId: any) =>
                      categoryOptions.find(
                        (cat: any) => cat.value === catId
                      ) || {
                        value: "undefined",
                        label: "Unknown Category",
                      }
                  )}
                  onChange={(selectedOptions) => {
                    const formattedCategories = selectedOptions.map(
                      (option) => option.value
                    );
                    setFieldValue("category", formattedCategories);
                  }}
                />
                <ErrorMessage
                  name="category"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Cuisine Multi-Select Dropdown */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Cuisine*
                </label>
                <Select
                  isMulti
                  options={cuisineOptions}
                  placeholder="Select cuisines"
                  name="cuisine"
                  className="mt-2"
                  value={values.cuisine.map(
                    (cuiId: any) =>
                      cuisineOptions.find(
                        (cui: any) => cui.value === cuiId
                      ) || {
                        value: "undefined",
                        label: "Unknown Category",
                      }
                  )}
                  onChange={(selectedOptions) => {
                    const formattedCuisines = selectedOptions.map(
                      (option) => option.value
                    );
                    setFieldValue("cuisine", formattedCuisines);
                  }}
                />
                <ErrorMessage
                  name="cuisine"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Order Type Multi-Select Dropdown */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Order Type*
                </label>
                <Select
                  isMulti
                  options={[
                    { value: "Dine In", label: "Dine In" },
                    { value: "Delivery", label: "Delivery" },
                    { value: "Pickup", label: "Pickup" },
                  ]}
                  placeholder="Select order types"
                  name="orderType"
                  className="mt-2"
                  value={values?.orderType?.map((typeId: any) => ({
                    value: typeId,
                    label: typeId,
                  }))}
                  onChange={(selectedOptions) => {
                    const formattedOrderTypes = selectedOptions.map(
                      (option) => option.value
                    );
                    setFieldValue("orderType", formattedOrderTypes);
                  }}
                />
                <ErrorMessage
                  name="orderType"
                  component="div"
                  className="text-red-500"
                />
              </div>

              {/* Pin Code Field */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Pin Code*
                </label>
                <input
                  type="text"
                  value={pinCode}
                  onChange={handlePinCodeChange}
                  onBlur={handlePinCodeBlur}
                  onKeyDown={handlePinCodeKeyDown}
                  className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                  placeholder="Enter your pin code"
                />
              </div>

              {/* Address Field */}
              <div className="mb-3">
                <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Address
                </label>
                <div className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none">
                  {address || "Address will appear here"}
                </div>
              </div>

              {isMapVisible && (
                <>
                  <div className="mb-3 text-center font-bold text-blue-600">
                    Choose exact location from the map.
                  </div>
                  <div className="mb-3">
                    <LoadScript googleMapsApiKey={envConfig.MapsAPIKey}>
                      <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={mapCenterLocation}
                        zoom={12}
                        onClick={handleMapClick}
                      >
                        {location && <Marker position={location} />}
                      </GoogleMap>
                    </LoadScript>
                  </div>
                </>
              )}

              <div className="mb-6 flex flex-row items-center gap-10 sm:flex-row sm:gap-5">
                {/* Restaurant Logo Upload */}
                <div className="flex-column mb-3 mt-4 flex-1 items-center gap-3">
                  <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                    Restaurant Logo
                  </label>
                  <Card className="grid h-full w-full grid-cols-1 gap-3 rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
                    <div className="flex h-full w-full flex-col items-center justify-center">
                      {brandImage ? (
                        <div className="relative flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700">
                          <img
                            src={brandImage}
                            alt="Restaurant logo"
                            className="h-[120px] w-[120px]"
                          />
                          <button
                            className="absolute right-1 top-1"
                            onClick={handleDeleteLogoFile}
                          >
                            <MdClose className="h-6 w-6" />
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => logoInputRef.current?.click()}
                          type="button"
                          className="flex h-full w-full flex-col items-center justify-center"
                        >
                          {isUploading ? (
                            <CircularProgress className="h-[80px] w-[80px]" />
                          ) : (
                            <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
                          )}
                          <h4 className="text-center text-xl font-bold text-brand-500 dark:text-white">
                            Upload Logo
                          </h4>
                        </button>
                      )}
                    </div>
                    <IKContext
                      publicKey={publicKey}
                      urlEndpoint={urlEndpoint}
                      authenticator={authenticator}
                    >
                      <IKUpload
                        onError={onError}
                        onSuccess={onLogoSuccess}
                        onUploadStart={onUploadProgress}
                        folder={"/restaurants"}
                        tags={["restaurant-uploads"]}
                        ref={logoInputRef}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                    </IKContext>
                  </Card>
                </div>

                {/* Restaurant Banner Upload */}
                <div className="flex-column mb-3 mt-4 flex-1 items-center gap-3">
                  <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                    Restaurant Banner
                  </label>
                  <Card className="grid h-full w-full grid-cols-1 gap-3 rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
                    <div className="flex h-full w-full flex-col items-center justify-center">
                      {bannerImage ? (
                        <div className="relative flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700">
                          <img
                            src={bannerImage}
                            alt="Restaurant banner"
                            className="h-[120px] w-[120px]"
                          />
                          <button
                            className="absolute right-1 top-1"
                            onClick={handleDeleteBannerFile}
                          >
                            <MdClose className="h-6 w-6" />
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => bannerInputRef.current?.click()}
                          type="button"
                          className="flex h-full w-full flex-col items-center justify-center"
                        >
                          {isBannerUploading ? (
                            <CircularProgress className="h-[80px] w-[80px]" />
                          ) : (
                            <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
                          )}
                          <h4 className="text-center text-xl font-bold text-brand-500 dark:text-white">
                            Upload Banner
                          </h4>
                        </button>
                      )}
                    </div>
                    <IKContext
                      publicKey={publicKey}
                      urlEndpoint={urlEndpoint}
                      authenticator={authenticator}
                    >
                      <IKUpload
                        onError={onError}
                        onSuccess={onBannerSuccess}
                        onUploadStart={onBannerUploadProgress}
                        folder={"/restaurants"}
                        tags={["restaurant-uploads"]}
                        ref={bannerInputRef}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                    </IKContext>
                  </Card>
                </div>
              </div>

              {/* Working Hours Section */}
              <div className="mb-6">
                <div className="mb-6">
                  <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                    Working Hours
                  </label>
                </div>
                {daysOfWeek.map((day) => (
                  <div key={day} className="mb-3 flex items-center space-x-4">
                    <div className="w-1/4 text-sm outline-none">{day}</div>

                    {/* Open Time Field */}
                    <div className="flex-1">
                      <label className="sr-only">Open Time</label>
                      <Field
                        type="time"
                        name={`workingHours.${day.toLowerCase()}.startTime`}
                        className="flex h-10 w-full rounded-lg border bg-white p-2 text-sm outline-none"
                        placeholder="Open"
                      />
                      <ErrorMessage
                        name={`workingHours.${day.toLowerCase()}.startTime`}
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <span className="text-sm font-medium">to</span>

                    {/* Close Time Field */}
                    <div className="flex-1">
                      <label className="sr-only">Close Time</label>
                      <Field
                        type="time"
                        name={`workingHours.${day.toLowerCase()}.endTime`}
                        className="flex h-10 w-full rounded-lg border bg-white p-2 text-sm outline-none"
                        placeholder="Close"
                      />
                      <ErrorMessage
                        name={`workingHours.${day.toLowerCase()}.endTime`}
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                ))}
              </div>

              <button
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                type="submit"
              >
                {loading ? `Saving...` : `Save & Continue`}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
    // </Card>
  );
};

export default RestaurantForm;
