import React, { useEffect, useRef, RefObject } from "react";
import { IKUpload, IKContext } from "imagekitio-react";
import { useImageUpload } from "controller/Hooks/useImageUpload";
import {
  authenticator,
  publicKey,
  urlEndpoint,
} from "controller/common/imageKit/imageKit";

interface ImageUploaderProps {
  setFieldValue: (field: string, value: any) => void;
  folder: string;
  uploadButton: JSX.Element;
  loadingIndicator: JSX.Element;
  imageDisplay:
    | JSX.Element
    | ((
        image: { name: string; url: string },
        resetImage: () => void
      ) => JSX.Element);
  initialImage?: { name: string; url: string };
  accept?: string;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  setFieldValue,
  folder,
  uploadButton,
  loadingIndicator,
  imageDisplay,
  initialImage,
  accept = "image/*",
}) => {
  const {
    image,
    isUploading,
    onSuccess,
    onError,
    onUploadProgress,
    resetImage,
  } = useImageUpload();

  const fileInputRef: RefObject<HTMLInputElement> = useRef(null);

  useEffect(() => {
    if (initialImage && !image) {
      onSuccess(initialImage);
    }
  }, [initialImage, image, onSuccess]);

  const handleUploadClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    fileInputRef.current?.click();
  };

  return (
    <div className="image-upload-container">
      {image ? (
        typeof imageDisplay === "function" ? (
          imageDisplay(image, resetImage)
        ) : (
          imageDisplay
        )
      ) : (
        <>
          {isUploading ? (
            loadingIndicator
          ) : (
            <button onClick={handleUploadClick}>{uploadButton}</button>
          )}
        </>
      )}

      <IKContext
        publicKey={publicKey}
        urlEndpoint={urlEndpoint}
        authenticator={authenticator}
      >
        <IKUpload
          folder={folder}
          onError={onError}
          onSuccess={(res) => {
            const imageData = {
              data: res,
              source: "ImageKit",
            };
            onSuccess(res);
            setFieldValue("image", imageData);
          }}
          onUploadStart={onUploadProgress}
          accept={accept}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
      </IKContext>
    </div>
  );
};

export default ImageUploader;
