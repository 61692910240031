import { useState } from "react";

interface Image {
  url: string;
  name: string;
}

interface UseImageUploadReturn {
  image: Image | null;
  isUploading: boolean;
  onSuccess: (res: any) => void;
  onError: (error: any) => void;
  onUploadProgress: () => void;
  resetImage: () => void;
}

export const useImageUpload = (
  initialImage: Image | null = null
): UseImageUploadReturn => {
  const [image, setImage] = useState<Image | null>(initialImage);
  const [isUploading, setIsUploading] = useState(false);

  const onSuccess = (res: any) => {
    setImage({ name: res.fileName, url: res.url });
    setIsUploading(false);
  };

  const onError = (error: any) => {
    console.error("Upload Error:", error);
    setIsUploading(false);
  };

  const onUploadProgress = () => {
    setIsUploading(true);
  };

  const resetImage = () => {
    setImage(null);
  };

  return {
    image,
    isUploading,
    onSuccess,
    onError,
    onUploadProgress,
    resetImage,
  };
};
