import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "components/card";
import { useRestaurant } from "./useRestaurant";
import AddModifier from "../modifiers/addModifier";
import RestaurantForm from "./RestaurantForm/index";
import Menu from "./menu/Menu";
import { useNavigate } from "react-router-dom";

const AddRestaurant = () => {
  const { id } = useParams<{ id?: string }>();
  const isEditMode = !!id;

  const isStepSkipped = (step: number) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const {
    loading,
    userType,
    initialValues,
    validationSchema,
    onSubmit,
    options,
    brandImage,
    isUploading,
    bannerImage,
    isBannerUploading,
    onLogoSuccess,
    onBannerSuccess,
    onUploadProgress,
    onBannerUploadProgress,
    handleDeleteLogoFile,
    handleDeleteBannerFile,
    logoInputRef,
    bannerInputRef,
    isActive,
    setIsActive,
    location,
    setLocation,
    branchId,
    address,
    setAddress,
    setRestaurantId,
    oneRestaurantData,
    categoryOptions,
    cuisineOptions,
    mapCenterLocation,
    isMapVisible,
    handleMapClick,
    handlePinCodeChange,
    handlePinCodeBlur,
    handlePinCodeKeyDown,
    daysOfWeek,
    pinCode,
    paymentStatusOptions,
  } = useRestaurant(handleNext);

  React.useEffect(() => {
    setRestaurantId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [mapCenter] = useState({ lat: 10.8505, lng: 76.2711 });
  const navigate = useNavigate();

  const steps = [
    "Add Restaurant",
    "Add-On",
    "Menu",
    // , "Orders"
  ];


  const handleFinish = () => {
    navigate(`/${userType}/all-restaurant`);
  };

  // const handleBack = () =>
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleStepClick = (step: number) => {
    if (isEditMode) {
      setActiveStep(step);
    }
  };

  const modifiersData = oneRestaurantData?.branchDetails?.[0]?.modifiers || [];
  // const categoriesData =
  //   oneRestaurantData?.branchDetails?.[0]?.categoryDetails || [];
  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <RestaurantForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            loading={loading}
            options={options}
            brandImage={brandImage}
            bannerImage={bannerImage}
            isUploading={isUploading}
            isBannerUploading={isBannerUploading}
            onLogoSuccess={onLogoSuccess}
            onBannerSuccess={onBannerSuccess}
            onUploadProgress={onUploadProgress}
            onBannerUploadProgress={onBannerUploadProgress}
            handleDeleteLogoFile={handleDeleteLogoFile}
            handleDeleteBannerFile={handleDeleteBannerFile}
            isActive={isActive}
            setIsActive={setIsActive}
            logoInputRef={logoInputRef}
            bannerInputRef={bannerInputRef}
            location={location}
            setAddress={setAddress}
            address={address}
            mapCenter={mapCenter}
            setLocation={setLocation}
            handleMapClick={handleMapClick}
            handleNext={handleNext}
            categoryOptions={categoryOptions}
            cuisineOptions={cuisineOptions}
            mapCenterLocation={mapCenterLocation}
            isMapVisible={isMapVisible}
            handlePinCodeChange={handlePinCodeChange}
            handlePinCodeBlur={handlePinCodeBlur}
            handlePinCodeKeyDown={handlePinCodeKeyDown}
            daysOfWeek={daysOfWeek}
            pinCode={pinCode}
            paymentStatusOptions={paymentStatusOptions}
          />
        );
      case 1:
        return (
          <AddModifier
            branchId={branchId}
            modifiersData={modifiersData}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <Menu
            branchId={branchId}
            // categoriesData={categoriesData}
            // menuData={menuData}
            // handleNext={handleNext}
          />
        );
      default:
        return <div>Unknown Step</div>;
    }
  };

  return (
    <Box sx={{ width: "100%", mb: 10 }}>
      <Stepper activeStep={activeStep} sx={{ mt: 4 }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              onClick={() => handleStepClick(index)}
              style={{ cursor: isEditMode ? "pointer" : "default" }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you're finished
          </Typography>
          <Button onClick={handleFinish}>Finish</Button>
        </>
      ) : (
        <>
          <Card extra={"w-full h-full mt-4 mb-4"}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              {/* <Button
                onClick={handleBack}
                disabled={activeStep === 0}
                sx={{ position: "absolute", top: 16, left: 16 }}
              >
                Back
              </Button> */}
            </Box>
            <Box sx={{ mt: 2, mb: 1 }}>{renderStepContent(activeStep)}</Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 6 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleFinish}>
                {activeStep === steps.length - 1 ? "Finish" : ""}
              </Button>
            </Box>
          </Card>
        </>
      )}
    </Box>
  );
};

export default AddRestaurant;
