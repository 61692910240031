import { getDBInstance } from "./dbServices";
import dayjs from "dayjs";

/* Order services */

export const getOrdersOfBranch = async ({
  page = 0,
  rowsPerPage = 10,
  userType,
  branchId,
}: any) => {
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  // Count documents that match the branchId
  const totalDocumentCount = await getDBInstance(userType)
    .collection("orders")
    .count({ branchId });

  // Aggregate with $match to filter by branchId
  const result = await getDBInstance(userType)
    .collection("orders")
    .aggregate([{ $match: { branchId } }, { $skip: skip }, { $limit: limit }]);

  return { data: result, totalDocumentCount };
};

export const getOrders = async ({
  page = 0,
  rowsPerPage = 10,
  userType,
  filter,
  branchId = null,
}: any) => {
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;
  const currentDate = dayjs();

  let dateFilter: any = {};
  if (filter === "today") {
    dateFilter = {
      "metadata.createdAt": {
        $gte: currentDate.startOf("day").toDate(),
        $lte: currentDate.endOf("day").toDate(),
      },
    };
  } else if (filter === "thisWeek") {
    dateFilter = {
      "metadata.createdAt": {
        $gte: currentDate.startOf("week").toDate(),
        $lte: currentDate.endOf("week").toDate(),
      },
    };
  } else if (filter === "thisMonth") {
    dateFilter = {
      "metadata.createdAt": {
        $gte: currentDate.startOf("month").toDate(),
        $lte: currentDate.endOf("month").toDate(),
      },
    };
  }

  if (userType === "branch" && branchId) {
    dateFilter.branchId = branchId;
  }

  const totalDocumentCount = await getDBInstance(userType)
    .collection("orders")
    .count(dateFilter);

  const result = await getDBInstance(userType)
    .collection("orders")
    .aggregate([{ $match: dateFilter }, { $skip: skip }, { $limit: limit }]);

  return { data: result, totalDocumentCount };
};
