import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useContext } from "react";
import {
  sendEmailAfterLogin,
  verifyOTP,
} from "controller/services/authServices";
import { UserContext } from "controller/context/userContext";
import { AlertContext } from "controller/context/alertContext";
import { Alert, Snackbar } from "@mui/material";
import { EmailResponse, OTPResponse } from "./interface";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// import { useNavigate } from "react-router-dom";

export default function TwoStepAuth() {
  // const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { setAlert, active, type, message } = React.useContext(AlertContext);
  const { user, currentApp, userType } = useContext(UserContext);
  const [resendLoading, setResendLoading] = React.useState(false);
  const [countdown, setCountdown] = React.useState(60);
  const [canResend, setCanResend] = React.useState(false);

  React.useEffect(() => {
    // Start a countdown for the resend button cooldown
    let timer: string | number | NodeJS.Timeout = null;
    if (!canResend) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setCanResend(true);
            return 60; // Reset for next use
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [canResend]);

  const initialValues = {
    otp: "",
  };

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required("Please enter your otp"),
  });

  const onVerifyOtp = async (data: { otp: string }) => {
    setLoading(true);
    try {
      const result = (await verifyOTP({
        email: user?.profile?.email,
        submittedOtp: data.otp,
        currentApp,
      })) as OTPResponse;
      if (result.verified === true) {
        localStorage.setItem("isVerified", JSON.stringify(true));
        localStorage.setItem("userType", userType);
        setAlert({
          active: true,
          type: "success",
          message: result.message,
        });
        setLoading(false);
        setTimeout(() => {
          // navigate(`/${userType}/default`);
          window.location.replace(`/${userType}/default`);
        }, 1000);
        // setTimeout(() => {
        //   window.location.reload();
        //   // navigate('/admin');
        // }, 1000);
      } else {
        setAlert({
          active: true,
          type: "error",
          message: result.message,
        });
        setLoading(false);
      }
    } catch (error: any) {
      setAlert({
        active: true,
        type: "error",
        message: error.message,
      });
      setLoading(false);
    }
  };

  const onResendOtp = async () => {
    try {
      if (!canResend) return;
      setResendLoading(true);
      const isEmailSent = (await sendEmailAfterLogin({
        toEmail: user?.profile?.email,
      })) as EmailResponse;
      if (isEmailSent?.status === 202 || isEmailSent?.status === 200) {
        setAlert({
          active: true,
          type: "success",
          message: "A new OTP has been sent to your email.",
        });
        setResendLoading(false);
        setCanResend(false);
        setCountdown(60);
      }
    } catch (error) {
      setResendLoading(false);
      setCanResend(false);
      setCountdown(60);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to resend OTP.",
      });
    }
  };

  return (
    <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
      <FixedPlugin />
      <main className={`mx-auto min-h-screen`}>
        <div className="relative flex">
          <div className="bg-neutral-50 mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
            <div className="mx-auto mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
              <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                {/* Sign in section */}
                <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                  <h4 className="mb-2.5 text-3xl font-bold text-navy-700 dark:text-white">
                    Two Factor Authentication
                  </h4>
                  <p className="mb-5 ml-1 text-sm text-gray-600">
                    Check Your Email: We' sent a 6-digit verification code.
                    Please enter it below to verify your account -
                    dev@coderythm.com.
                  </p>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onVerifyOtp}
                  >
                    <Form>
                      <div className="mb-3">
                        <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                          Enter OTP *
                        </label>
                        <Field
                          type="text"
                          name="otp"
                          className={
                            "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          }
                        />
                        <ErrorMessage
                          name="otp"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <button
                        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        type="submit"
                      >
                        {loading ? "Loading..." : "Verify OTP"}
                      </button>
                      <button
                        className="linear mt-2 w-full rounded-xl bg-gray-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-gray-700 active:bg-gray-800 dark:bg-gray-400 dark:text-white dark:hover:bg-gray-300 dark:active:bg-gray-200"
                        type="submit"
                        disabled={!canResend}
                        onClick={onResendOtp}
                      >
                        {resendLoading
                          ? "Loading..."
                          : `Resend OTP ${canResend ? "" : `(${countdown})`}`}
                      </button>
                    </Form>
                  </Formik>
                </div>
                <Snackbar
                  open={active}
                  autoHideDuration={3000}
                  onClose={() => {}}
                  anchorOrigin={{ horizontal: "center", vertical: "top" }}
                >
                  <Alert
                    severity={type}
                    className="mx-3 my-3"
                    variant="filled"
                    sx={{ width: "100%" }}
                  >
                    {message}
                  </Alert>
                </Snackbar>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
